var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      (_vm.fconfig.sep != false) &
      !(_vm.fconfig.hide & !_vm.form.formData[_vm.fname] & !_vm.form.active) &
      (_vm.findex > 0)
    )?_c('hr'):_vm._e(),(
      (_vm.fconfig.type != 'translations') &
      !(_vm.fconfig.hide & !_vm.form.formData[_vm.fname] & !_vm.form.active)
    )?_c('div',{staticClass:"row"},[(_vm.fconfig.title)?_c('dd',{class:_vm.size_name},[_vm._v(" "+_vm._s(_vm.fconfig.title)+" ")]):_vm._e(),(_vm.fconfig.children)?_c('dt',{class:_vm.size_value},[_c('div',{staticClass:"row"},_vm._l((_vm.fconfig.children),function(cfconfig,cfname,index){return _c('div',{key:index,class:'col-md-' + cfconfig.size},[(cfconfig.enabled != false)?_c('Field',_vm._g({attrs:{"fname":cfname,"fconfig":cfconfig,"form":_vm.form}},_vm.$listeners)):_vm._e()],1)}),0),(_vm.fconfig.description)?_c('small',{staticClass:"form-text text-muted w-100",domProps:{"textContent":_vm._s(_vm.fconfig.description)}}):_vm._e()]):(_vm.fconfig.translate && _vm.fconfig.type == 'CInput')?_c('FieldTransText',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.translate && _vm.fconfig.type == 'CKInput')?_c('FieldTransRichText',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'CSelect')?_c('FieldSelect',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'CInputCheckbox')?_c('FieldMultipleCheckbox',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'Cropper')?_c('FieldCropper',_vm._g({attrs:{"config":_vm.field_config}},_vm.$listeners)):(_vm.fconfig.type == 'CInput')?_c('FieldText',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'CNumber')?_c('FieldNumber',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'CKInput')?_c('FieldRichText',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'CInputRadioGroup')?_c('FieldRadio',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'CInputCheckboxB')?_c('FieldCheckbox',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'CInputCheckboxNested')?_c('FieldMultipleCheckboxNested',{attrs:{"config":_vm.field_config}}):(_vm.fconfig.type == 'CDate')?_c('FieldDate',{attrs:{"config":_vm.field_config}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }