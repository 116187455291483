<template>
  <dt :class="config.size_value">
    <CSpinner v-if="!options" color="secondary" size="sm" />
    <span v-else-if="!config.form.active || config.fconfig.readonly">
      {{ SelectedLabel }}
    </span>
    <FieldRadioGroup
      v-else-if="config.form.active && !config.fconfig.readonly"
      :options="options"
      :checked.sync="config.form.formData[config.fname]"
      :inline="true"
      :description="config.fconfig.description"
      :is-valid="config.is_valid(config.fname)"
      :invalid-feedback="config.invalid_feedback(config.fname)"
    />
    <small
      v-if="
        config.fconfig.description &&
        config.form.active &&
        !config.fconfig.readonly
      "
      class="form-text text-muted w-100"
      v-text="config.fconfig.description"
    ></small>
  </dt>
</template>

<script>
import FieldRadioGroup from "./FieldRadioGroup";

export default {
  props: ["config"],
  data() {
    return {
      options: null,
    };
  },
  components: {
    FieldRadioGroup,
  },
  created() {
    Promise.resolve(this.config.fconfig.options).then((opts) => {
      this.options = opts;
    });
  },
  watch: {
    "config.fconfig.options"() {
      //   console.log(this.config.fconfig.options);
      //  this.options = null
      Promise.resolve(this.config.fconfig.options).then((opts) => {
        this.options = opts;
      });
    },
  },
  computed: {
    SelectedLabel() {
      for (var i = 0; i < this.options.length; i++) {
        if (
          this.options[i].value == this.config.form.formData[this.config.fname]
        ) {
          return this.options[i].label;
        }
      }
      return this.config.form.formData[this.config.fname];
    },
  },
};
</script>
