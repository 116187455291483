<template>
  <div>
    <hr
      v-if="
        (fconfig.sep != false) &
        !(fconfig.hide & !form.formData[fname] & !form.active) &
        (findex > 0)
      "
    />
    <div
      class="row"
      v-if="
        (fconfig.type != 'translations') &
        !(fconfig.hide & !form.formData[fname] & !form.active)
      "
    >
      <dd :class="size_name" v-if="fconfig.title">
        {{ fconfig.title }}
        <!-- <div v-if="fconfig.translate && fconfig.type == 'CKInput' && form.active">
        <div v-for="lang in languages" :key="lang.language">
          <CButton
            variant="outline"
            color="secondary"
            :pressed="lang.language == trans_current_lang"
            v-on:click="TransSetLang(lang.language)"
          >
            <CIcon :content="lang.flag" /> {{ lang.title }}
          </CButton>
        </div>
      </div> -->
      </dd>

      <dt :class="size_value" v-if="fconfig.children">
        <div class="row">
          <div
            v-for="(cfconfig, cfname, index) in fconfig.children"
            :key="index"
            :class="'col-md-' + cfconfig.size"
          >
            <Field
              v-if="cfconfig.enabled != false"
              :fname="cfname"
              :fconfig="cfconfig"
              :form="form"
              v-on="$listeners"
            />
          </div>
        </div>
        <small
          v-if="fconfig.description"
          class="form-text text-muted w-100"
          v-text="fconfig.description"
        ></small>
      </dt>
      <FieldTransText
        v-else-if="fconfig.translate && fconfig.type == 'CInput'"
        :config="field_config"
      />
      <FieldTransRichText
        v-else-if="fconfig.translate && fconfig.type == 'CKInput'"
        :config="field_config"
      />
      <FieldSelect
        v-else-if="fconfig.type == 'CSelect'"
        :config="field_config"
      />
      <FieldMultipleCheckbox
        v-else-if="fconfig.type == 'CInputCheckbox'"
        :config="field_config"
      />
      <FieldCropper
        v-else-if="fconfig.type == 'Cropper'"
        :config="field_config"
        v-on="$listeners"
      />
      <FieldText v-else-if="fconfig.type == 'CInput'" :config="field_config" />
      <FieldNumber
        v-else-if="fconfig.type == 'CNumber'"
        :config="field_config"
      />
      <FieldRichText
        v-else-if="fconfig.type == 'CKInput'"
        :config="field_config"
      />
      <FieldRadio
        v-else-if="fconfig.type == 'CInputRadioGroup'"
        :config="field_config"
      />
      <FieldCheckbox
        v-else-if="fconfig.type == 'CInputCheckboxB'"
        :config="field_config"
      />
      <FieldMultipleCheckboxNested
        v-else-if="fconfig.type == 'CInputCheckboxNested'"
        :config="field_config"
      />

      <FieldDate v-else-if="fconfig.type == 'CDate'" :config="field_config" />
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";
// import {
//   RichTextEditorPlugin,
//   Toolbar,
//   HtmlEditor,
// } from "@syncfusion/ej2-vue-richtexteditor";
// import {
//   DropDownTreePlugin,
//   AutoCompletePlugin,
// } from "@syncfusion/ej2-vue-dropdowns";

// import CKEditor from "@ckeditor/ckeditor5-vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import CVSelect from "./CVSelect";
import FieldTransText from "./FieldTransText";
import FieldTransRichText from "./FieldTransRichText";
import FieldSelect from "./FieldSelect";
import FieldMultipleCheckbox from "./FieldMultipleCheckbox";
import FieldMultipleCheckboxNested from "./FieldMultipleCheckboxNested";
import FieldCropper from "./FieldCropper";
import FieldText from "./FieldText";
import FieldNumber from "./FieldNumber";
import FieldDate from "./FieldDate";
import FieldRichText from "./FieldRichText";
import FieldRadio from "./FieldRadio";
import FieldCheckbox from "./FieldCheckbox";
// Vue.use(DropDownTreePlugin);
// Vue.use(RichTextEditorPlugin);
// Vue.use(AutoCompletePlugin);
const _ = require("lodash");

export default {
  name: "Field",
  //   multiselectOptions: [],
  components: {
    FieldTransText,
    FieldTransRichText,
    FieldSelect,
    FieldMultipleCheckbox,
    FieldMultipleCheckboxNested,
    FieldCropper,
    FieldText,
    FieldNumber,
    FieldRichText,
    FieldRadio,
    FieldCheckbox,
    FieldDate,
    // vSelect,
    // CVSelect,
    // ckeditor: CKEditor.component,
  },
  data() {
    return {
      // value: JSON.parse(JSON.stringify(this.field.value)),
      //   languages: Languages,
      // //   CkEditor: ClassicEditor,
      //   trans_current_lang: this.$i18n.locale,
      subsize: this.fconfig.subsize || [4, 8],
      //   CInputCheckbox_search: "",
      //   CInputCheckbox_options: null,
    };
  },
  //   created() {
  //     if (this.field.type == "CInputCheckbox") {
  //       this.form.formDataOptions[this.field.options].then(
  //         (e) => {
  //           this.CInputCheckbox_options = e;
  //         },
  //         (reason) => {
  //           this.CInputCheckbox_options = [];
  //         }
  //       );
  //     }
  //   },
  props: ["fname", "fconfig", "form", "findex"],
  computed: {
    field_config() {
      let f_name = this.fname;
      let f_name_list = f_name.split(".");
      // console.log(f_name_list[0],f_name_list[f_name_list.length-1])
      // console.log(this.form)
      if (f_name_list.length > 1) {
        let subform = Object.assign({}, this.form);
        subform.formData = this.form.formData[f_name_list[0]];
        // console.log(subform)
        return {
          fname: f_name_list[f_name_list.length - 1],
          fconfig: this.fconfig,
          form: subform,
          size_value: this.size_value,
          is_valid: this.IsValid,
          invalid_feedback: this.GetError,
        };
      } else {
        return {
          fname: this.fname,
          fconfig: this.fconfig,
          form: this.form,
          size_value: this.size_value,
          is_valid: this.IsValid,
          invalid_feedback: this.GetError,
        };
      }
    },
    // CInputCheckbox_search_options() {
    //   let self = this;
    //   let res = this.CInputCheckbox_options.reduce(function (map, obj) {
    //     if (
    //       self.CInputCheckbox_search == "" ||
    //       obj.label
    //         .toLowerCase()
    //         .indexOf(self.CInputCheckbox_search.toLowerCase()) >= 0
    //     ) {
    //       map.push(obj);
    //     }
    //     return map;
    //   }, []);
    //   return res;
    // },

    size_name() {
      return "col-sm-" + this.subsize[0] || 4;
    },
    size_value() {
      return "col-sm-" + this.subsize[1] || 8;
    },
    //     value: {
    //         get(){
    //             return this.field.value
    //         },
    //         set(value){
    //             // this.field.value = value
    //             // return valueù
    //             this.$emit('input', value)
    //             console.log("set value", value)
    //         }
    //     }
  },
  //   provide: {
  //     richtexteditor: [Toolbar, HtmlEditor],
  //   },
  methods: {
    GetError(field) {
      let errors = _.get(this.form.errors, field, null);
      // console.log("GetError", errors)
      return errors ? errors.join("<br>") : null;
    },
    IsValid(field) {
      let errors = this.GetError(field);
      // console.log("IsValid", errors? false: null)
      return errors ? false : null;
    },
    TransSetLang(value) {
      console.log("set trans", value);
      this.trans_current_lang = value;
    },
    // setSelected(value) {
    //   console.log("Field changed", this.field.name, value);
    //   this.$emit("field_changed", { name: this.field.name, value: value });
    //   // this.field.value=value
    //   // console.log("changed", this.field.value)
    // },
    // GetSelValue(value, options) {
    //   for (var i = 0; i < options.length; i++) {
    //     if (options[i].value == value) {
    //       return options[i].label;
    //     }
    //   }
    //   return value;
    // },
    // GetChkValue(value, options) {
    //   let res = [];
    //   for (var i = 0; i < options.length; i++) {
    //     if (value.includes(options[i].value)) {
    //       res.push(options[i].label);
    //     }
    //   }
    //   return res.join(",");
    // },
  },
  //   computed: {
  //     value: {
  //       get: function () {
  //         if (this.field.options) {
  //             if (this.field.options.reduce(function (map, obj) {
  //                 map.push(obj.value)
  //                 return map
  //             },[]).includes(this.b_value)){
  //                 return this.b_value;
  //             }else{
  //                 return ""
  //             }
  //         } else {
  //           return this.b_value;
  //         }
  //       },
  //       // setter
  //       set: function (newValue) {
  //         this.b_value = newValue;
  //       },
  //     },
  //   },
  watch: {
    //   "field.value": function (nV, oV) {
    //     console.log("changed value", this.field.name, nV, oV);
    //     // this.field.new_value = nV;
    //     //   console.log(this.field)
    //     // this.$emit("field_changed", { name: this.field.name, value: nV });
    //   },
    //     //     // "field.value": function (newVal, oldVal) {
    //     //     //   console.log("changed field", newVal, oldVal);
    //     //     // },
    // field: {
    //   handler(f) {
    //     // console.log("changed field", f.name, f.value);
    //     console.log("changed field", Date.now());
    //     // console.log("changed field");
    //   },
    //   deep: true,
    // },
    // field: {
    //   deep: true,
    //   handler() {
    //       console.log("watch field")
    //     if (
    //         this.field.options &&
    //       !this.field.options
    //         .reduce(function (map, obj) {
    //           map.push(obj.value);
    //           return map;
    //         }, [])
    //         .includes(this.value)
    //     ) {
    //       this.b_value=""
    //     }
    //   },
    // },
    // "field.options": function (nV, oV) {
    //   console.log("changed options", this.field.name, nV, oV);
    //   if (
    //     this.field.options &&
    //     !this.field.options
    //       .reduce(function (map, obj) {
    //         map.push(obj.value);
    //         return map;
    //       }, [])
    //       .includes(this.value)
    //   ) {
    //     this.value = 0;
    //   }
    // },
    // value: {
    //   deep: true,
    //   handler() {
    //     console.log(
    //       "changed value",
    //       this.field.name,
    //       this.value,
    //       this.field.value
    //     );
    //     if (this.field.translate) {
    //       let res = diff(this.field.value, this.value);
    //       this.field.new_value = res;
    //       this.$emit("field_changed", {
    //         name: "translations." + this.field.name,
    //         value: res,
    //       });
    //     } else {
    //       this.field.new_value = this.value;
    //       this.$emit("field_changed", {
    //         name: this.field.name,
    //         value: this.value,
    //       });
    //     }
    //   },
    // },
  },
};
</script>
