<template>
  <!-- <dt :class="size_value" v-else-if="field.type == 'CInputCheckboxB'">
      <span v-if="!form.active || field.readonly">
        {{ form.formData[field.name] ? field.label : "" }}
      </span>
      <div v-if="form.active && !field.readonly">
        <CInputCheckbox
          :label="field.label"
          :checked.sync="form.formData[field.name]"
          :inline="true"
        />
      </div>
    </dt> -->
  <dt :class="config.size_value">
    <span v-if="!config.form.active || config.fconfig.readonly">
      {{ config.form.formData[config.fname] ? config.fconfig.label : "" }}
    </span>
    <div v-else-if="config.form.active && !config.fconfig.readonly">
      <CInputCheckbox
        :label="config.fconfig.label"
        :checked.sync="config.form.formData[config.fname]"
        :inline="true"
      />
      <small
        v-if="config.fconfig.description"
        class="form-text text-muted w-100"
        v-text="config.fconfig.description"
      ></small>
    </div>
    <br />
  </dt>
</template>

<script>
export default {
  props: ["config"],
};
</script>
