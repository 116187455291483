<template>
  <dt :class="config.size_value">
    <span v-if="!config.form.active || config.fconfig.readonly">{{
      config.form.formData[config.fname]
    }}</span>
    <div v-if="config.form.active && !config.fconfig.readonly">
      <CInput
        :description="config.fconfig.description"
        horizontal
        type="number"
        v-model="config.form.formData[config.fname]"
        :is-valid="config.is_valid(config.fname)"
        :invalid-feedback="config.invalid_feedback(config.fname)"
      />
    </div>
  </dt>
</template>

<script>
export default {
  name: "FieldTransText",
  props: ["config"],
};
</script>
